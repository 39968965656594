import { render, staticRenderFns } from "./exerciseDetail.vue?vue&type=template&id=91ef8c66"
import script from "./exerciseDetail.vue?vue&type=script&lang=js"
export * from "./exerciseDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports