<template>
  <div class="exercise-detail-page w-100">
    <b-form @submit="submit">
      <basic-subheader
        :title="
          form_type === 'create'
            ? 'Thêm môn vận động mới'
            : 'Chỉnh sửa môn vận động'
        "
      >
        <template v-slot:actions>
          <b-button
            class="btn btn-light ml-3"
            type="button"
            pill
            @click="returnPage"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Arrows/arrow-left.svg" />
            </span>
            Trở về trước
          </b-button>
          <b-button class="btn btn-warning ml-3" type="button" pill>
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/refresh-cw.svg" />
            </span>
            Reset dữ liệu
          </b-button>
          <template v-if="form_type === 'create'">
            <b-button class="btn btn-primary ml-3" type="button" pill>
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
              </span>
              Tạo mới
            </b-button>
          </template>
          <template v-else>
            <b-button class="btn btn-danger ml-3" type="button" pill>
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/trash-2.svg" />
              </span>
              Xóa môn vận động
            </b-button>
            <b-button class="btn btn-primary ml-3" type="button" pill>
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/check.svg" />
              </span>
              Cập nhật
            </b-button>
          </template>
        </template>
      </basic-subheader>
      <b-container fluid class="exercise-detail-page__body mb-6 mt-6">
        <div class="card card-custom">
          <b-row class="border-bottom">
            <b-col class="col-6 col-lg-4 col-xl-3">
              <h6 class="d-flex align-items-center p-6 mb-0 text-primary">
                <span class="svg-icon mr-3">
                  <inline-svg src="/media/svg/icons/Neolex/Basic/info.svg" />
                </span>
                Thông Tin Môn Vận Động
              </h6>
            </b-col>
          </b-row>
          <b-row align-h="center" class="p-6">
            <b-col cols="8">
              <b-row>
                <b-col>
                  <basic-input
                    label="Tên vận động: *"
                    placeholder="Nhập tên của vận động"
                    name="name"
                    :value.sync="form.name"
                    :state="validationState.name"
                    :invalidFeedback="error.name"
                  ></basic-input>
                </b-col>
                <b-col>
                  <basic-input
                    label="Mã vận động: *"
                    placeholder="Nhập mã của vận động"
                    name="code"
                    :value.sync="form.code"
                    :state="validationState.code"
                    :invalidFeedback="error.code"
                  ></basic-input>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <basic-input
                    label="Mô tả ngắn vận động: *"
                    placeholder="Nhập mô tả ngắn của vận động"
                    name="shortDescription"
                    :value.sync="form.shortDescription"
                    :state="validationState.shortDescription"
                    :invalidFeedback="error.shortDescription"
                  ></basic-input>
                </b-col>
                <b-col>
                  <basic-input
                    label="Mô tả vận động: *"
                    placeholder="Nhập mô tả của vận động"
                    name="description"
                    :value.sync="form.description"
                    :state="validationState.description"
                    :invalidFeedback="error.description"
                  ></basic-input>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <basic-select
                    label="Nhóm vận động: *"
                    placeholder="Chọn nhóm vận động"
                    name="exerciseCategory"
                    apiPath="/exerciseCategory"
                    :value.sync="form.exerciseCategory"
                    :state="validationState.exerciseCategory"
                    :invalidFeedback="error.exerciseCategory"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </b-form>
  </div>
</template>
<script>
export default {
  props: {
    form_type: {
      type: String,
      default: 'detail',
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      form: {
        name: null,
        code: null,
        shortDescription: null,
        description: null,
        createdDate: null,
        exerciseCategory: null,
      },
      validationState: {
        name: null,
        code: null,
        shortDescription: null,
        description: null,
        createdDate: null,
        exerciseCategory: null,
      },
      error: {
        name: null,
        code: null,
        shortDescription: null,
        description: null,
        createdDate: null,
        exerciseCategory: null,
      },
    };
  },

  computed: {
    exerciseData: {
      get() {
        let newBranch = {
          name: this.form.name,
          code: this.form.code,
          shortDescription: this.form.shortDescription,
          description: this.form.description,
          createdDate: this.form.createdDate,
          exerciseCategory: this.form.exerciseCategory,
        };
        return newBranch;
      },
      set(data) {
        this.form.name = data.name;
        this.form.code = data.code;
        this.form.shortDescription = data.shortDescription;
        this.form.description = data.description;
        this.form.createdDate = data.createdDate;
        this.form.exerciseCategory = data.exerciseCategory;
      },
    },
  },
  methods: {
    returnPage() {
      this.$router.push({
        name: 'exercise_list',
      });
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16),
      );
    },
    nameValidation() {
      let nameState = null;
      let nameValidation = null;
      if (!this.form.name || !this.form.name.trim().length) {
        nameState = false;
        nameValidation = 'Không được bỏ trống tên';
      } else {
        nameState = true;
        nameValidation = null;
      }
      this.validationState.name = nameState;
      this.error.name = nameValidation;
    },
    codeValidation() {
      let codeState = null;
      let codeValidation = null;
      if (!this.form.code || !this.form.code.trim().length) {
        codeState = false;
        codeValidation = 'Không được bỏ trống mã';
      } else {
        codeState = true;
        codeValidation = null;
      }
      this.validationState.code = codeState;
      this.error.code = codeValidation;
    },
    shortDescriptionValidation() {
      let shortDescriptionState = null;
      let shortDescriptionValidation = null;
      if (
        !this.form.shortDescription ||
        !this.form.shortDescription.trim().length
      ) {
        shortDescriptionState = false;
        shortDescriptionValidation = 'Không được bỏ trống miêu tả ngắn';
      } else {
        shortDescriptionState = true;
        shortDescriptionValidation = null;
      }
      this.validationState.shortDescription = shortDescriptionState;
      this.error.shortDescription = shortDescriptionValidation;
    },
    descriptionValidation() {
      let descriptionState = null;
      let descriptionValidation = null;
      if (!this.form.description || !this.form.description.trim().length) {
        descriptionState = false;
        descriptionValidation = 'Không được bỏ trống miêu tả';
      } else {
        descriptionState = true;
        descriptionValidation = null;
      }
      this.validationState.description = descriptionState;
      this.error.description = descriptionValidation;
    },
    exerciseCategoryValidation() {
      let exerciseCategoryState = null;
      let exerciseCategoryValidation = null;
      if (!this.form.exerciseCategory) {
        exerciseCategoryState = false;
        exerciseCategoryValidation = 'Không được bỏ trống nhóm vận động';
      } else {
        exerciseCategoryState = true;
        exerciseCategoryValidation = null;
      }
      this.validationState.exerciseCategory = exerciseCategoryState;
      this.error.exerciseCategory = exerciseCategoryValidation;
    },
    isFormValid() {
      let isValid = true;
      for (let field of Object.keys(this.form)) {
        let validationFunction = `${field}Validation`;
        if (
          this[validationFunction] &&
          typeof this[validationFunction] === 'function'
        ) {
          this[validationFunction]();
        }
      }

      for (let validate of Object.keys(this.validationState)) {
        if (this.validationState[validate] === false) {
          isValid = false;
          break;
        }
      }
      return isValid;
    },
    create() {
      this.$store.commit('context/setLoading', true);
      this.$api
        .post('exercise', {
          ...this.exerciseData,
          id: this.uuidv4(),
          createdDate: new Date().toUTCString(),
        })
        .then(() => {
          this.$toastr.s({
            title: 'Thành công!',
            msg: 'Thêm vận động thành công',
          });
        })
        .catch((error) => {
          if (error) {
            // if (error.code === 'duplicated_code') {
            //   this.validationState.code = false;
            //   this.error.code = `Mã Nhân Viên không được trùng ${this.form.code}`;
            //   this.$toastr.e({
            //     title: 'Lỗi !',
            //     msg: 'Mã Nhân Viên không được trùng',
            //   });
            // }
          }
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
    },
    edit() {
      this.$store.commit('context/setLoading', true);
      this.$api
        .put(`exercise/${this.id}`, this.exerciseData)
        .then(() => {
          this.$toastr.s({
            title: 'Thành công!',
            msg: 'Cập nhật vận động thành công',
          });
        })
        .catch((error) => {
          if (error) {
            // if (error.code === 'duplicated_code') {
            //   this.validationState.code = false;
            //   this.error.code = `Đã có Chi nhánh với Mã ${this.form.code}`;
            //   this.$toastr.e({
            //     title: 'Lỗi!',
            //     msg: 'Mã Chi nhánh không được trùng',
            //   });
            // }
          }
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
    },
    submit($event) {
      $event.preventDefault();
      let isFormValid = this.isFormValid();
      if (isFormValid) {
        if (isFormValid) {
          if (this.form_type === 'create') {
            this.create();
          } else if (this.form_type === 'edit') {
            this.edit();
          }
        }
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 200);
      }
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 200);
      return;
    },
    async loadExerciseData() {
      await this.$api.get(`branch/${this.id}`).then(({ data }) => {
        this.exerciseData = JSON.parse(JSON.stringify(data));
      });
    },
  },
  async mounted() {
    this.$store.commit('context/setLoading', true);
    if (this.id) {
      await this.loadExerciseData();
    }
    this.$store.commit('context/setLoading', false);
  },
  // beforeMount() {},
  // beforeRouteLeave(to, from, next) {},
};
</script>
